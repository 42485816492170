// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
const firebaseConfig = {
  apiKey: "AIzaSyBXrG2KUbElsQYN_y08_cflbHjDsqlWQJE",
  authDomain: "idfc1-e97b8.firebaseapp.com",
  databaseURL: "https://idfc1-e97b8-default-rtdb.firebaseio.com",
  projectId: "idfc1-e97b8",
  storageBucket: "idfc1-e97b8.appspot.com",
  messagingSenderId: "695955713841",
  appId: "1:695955713841:web:ea9d0c26e76813b1ff0a10",
  measurementId: "G-3X1FT67KMB"
};
// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
export { app };